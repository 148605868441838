export const STATUS_IMPORTED = 1000
export const STATUS_UNAUTHENTICATED = 1401
export const STATUS_HASNT_PERMISSION = 1490
export const STATUS_ACTIVE = 1
export const STATUS_ANY_ACTIVE = 201
export const STATUS_DISABLED_ON_FACEBOOK = 2
export const STATUS_DISABLED = 1491
export const STATUS_UNSETTLED = 3
export const STATUS_PENDING_RISK_REVIEW = 7
export const STATUS_PENDING_SETTLEMENT = 8
export const STATUS_IN_GRACE_PERIOD = 9
export const STATUS_PENDING_CLOSURE = 100
export const STATUS_CLOSED = 101
export const STATUS_ANY_CLOSED = 202
export const STATUS_CLOSED_CLOSED = 'CLOSED'
export const STATUS_UNKNOWN = 'UNKNOWN'
export const STATUS_ACTIVE_ACTIVE = 'ACTIVE'
export const STATUS_NEED_RESEND_REQUEST = 1452
export const STATUS_ERROR = 1500
export const STATUS_UPDATING_INFO = 1202
export const STATUS_DELETED = 1204
export const STATUS_PENDING = 'PENDING'
export const STATUS_ENABLE = 'STATUS_ENABLE'
export const STATUS_ENABLED = 'ENABLED'
export const STATUS_ACCEPTED = 'ACCEPTED'
export const STATUS_CANCELED = 'CANCELED'
export const STATUS_SUSPENDED = 'SUSPENDED'
export const STATUS_PENDING_VERIFIED = 'STATUS_PENDING_VERIFIED'
export const STATUS_SELF_SERVICE_UNAUDITED = 'STATUS_SELF_SERVICE_UNAUDITED'
export const STATUS_DISABLE = 'STATUS_DISABLE'
export const STATUS_PENDING_CONFIRM = 'STATUS_PENDING_CONFIRM'
export const STATUS_CONFIRM_FAIL = 'STATUS_CONFIRM_FAIL'
export const STATUS_CONFIRM_FAIL_END = 'STATUS_CONFIRM_FAIL_END'
export const STATUS_PENDING_CONFIRM_MODIFY = 'STATUS_PENDING_CONFIRM_MODIFY'
export const STATUS_CONFIRM_MODIFY_FAIL = 'STATUS_CONFIRM_MODIFY_FAIL'
export const STATUS_LIMIT = 'STATUS_LIMIT'
export const STATUS_LIMIT_CODE = 1402
export const STATUS_WAIT_FOR_BPM_AUDIT = 'STATUS_WAIT_FOR_BPM_AUDIT'
export const STATUS_WAIT_FOR_PUBLIC_AUTH = 'STATUS_WAIT_FOR_PUBLIC_AUTH'
export const STATUS_CONTRACT_PENDING = 'STATUS_CONTRACT_PENDING'
export const STATUS_BILLING_HOLD = 'BILLING_HOLD'

export const STATUS_TITLE = {
  [STATUS_IMPORTED]: 'Active',
  [STATUS_UNAUTHENTICATED]: 'Access lost ',
  [STATUS_UNSETTLED]: 'Unsettled',
  [STATUS_HASNT_PERMISSION]: 'Has no permission',
  [STATUS_ACTIVE]: 'Active',
  [STATUS_ANY_ACTIVE]: 'Active',
  [STATUS_ACCEPTED]: 'Accepted',
  [STATUS_DISABLED_ON_FACEBOOK]: 'Disabled',
  [STATUS_DISABLED]: 'Disabled',
  [STATUS_PENDING_RISK_REVIEW]: 'In review',
  [STATUS_PENDING_SETTLEMENT]: 'Unsettled payment',
  [STATUS_IN_GRACE_PERIOD]: ' In grace period',
  [STATUS_PENDING_CLOSURE]: 'Pending closure',
  [STATUS_CLOSED]: 'Closed',
  [STATUS_ANY_CLOSED]: 'Closed',
  [STATUS_CLOSED_CLOSED]: 'Closed',
  [STATUS_ACTIVE_ACTIVE]: 'Active',
  [STATUS_NEED_RESEND_REQUEST]: 'Need resend request',
  [STATUS_ERROR]: 'Unknown error',
  [STATUS_UPDATING_INFO]: 'Updating info',
  [STATUS_DELETED]: 'Deleted',
  [STATUS_PENDING]: 'Pending',
  [STATUS_ENABLE]: 'Active',
  [STATUS_ENABLED]: 'Active',
  [STATUS_PENDING_VERIFIED]: 'Pending verified',
  [STATUS_SELF_SERVICE_UNAUDITED]: 'Service unaudited',
  [STATUS_DISABLE]: 'Disabled',
  [STATUS_PENDING_CONFIRM]: 'Pending confirm',
  [STATUS_CONFIRM_FAIL]: 'Confirm Fail',
  [STATUS_CONFIRM_FAIL_END]: 'Confirm fail end',
  [STATUS_PENDING_CONFIRM_MODIFY]: 'Pending confirm modify',
  [STATUS_CONFIRM_MODIFY_FAIL]: 'Confirm modify fail',
  [STATUS_LIMIT]: 'Restricted',
  [STATUS_LIMIT_CODE]: 'Restricted',
  [STATUS_WAIT_FOR_BPM_AUDIT]: 'Wait for BPM audit',
  [STATUS_WAIT_FOR_PUBLIC_AUTH]: 'Wait for public auth',
  [STATUS_CONTRACT_PENDING]: 'Contract pending',
  [STATUS_BILLING_HOLD]: 'Billing hold'
}

export const STATUS_TOOLTIP = {
  [STATUS_UNAUTHENTICATED]: 'Log in to your {service} profile, then switch the toggle on in AutomatedRules Integrations page.',
  [STATUS_UNSETTLED]: 'Fix issues connected with the payment method in your {service} ad account settings. ',
  [STATUS_HASNT_PERMISSION]: 'Reconnect your ad account and grant the requested permissions',
  [STATUS_DISABLED]: 'Submit an appeal to {service} and wait till your ad account is active again.',
  [STATUS_PENDING_RISK_REVIEW]: 'Wait till your ad account review is complete or connect another active ad account.',
  [STATUS_PENDING_SETTLEMENT]: 'Fix issues connected with the payment method in your {service} ad account settings. ',
  [STATUS_IN_GRACE_PERIOD]: 'Cancel your ad account deactivation on {service} or connect another active ad account. ',
  [STATUS_PENDING_CLOSURE]: 'Cancel your ad account deactivation on {service} or connect another active ad account. ',
  [STATUS_CLOSED]: 'Connect an active {service} ad account or create a new one.',
  [STATUS_ERROR]: 'Fix ad account errors on {service}. If the problem persists contact AutomatedRules Support.',
  [STATUS_DELETED]: 'Connect an active {service} ad account or create a new one.'
}

export const DEFAULT_ERROR_CODE_MSG = 'An unexpected error occurred. Please try again later. If this issue continues, please contact Support with the code: {error_code}'

export const ERROR_CODES = {
  403001: DEFAULT_ERROR_CODE_MSG,
  403002: DEFAULT_ERROR_CODE_MSG,
  403003: DEFAULT_ERROR_CODE_MSG
}
